

























































import { format } from 'date-fns';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop() documentLogs!: Array<any>;
  @Prop() documentId: any;

  documentLogFields: Array<any> = [
    {
      key: 'index',
      label: '#',
      sortable: false,
      thStyle: {
        width: '5%',
      },
    },
    {
      key: 'createdAt',
      label: 'Data',
      sortable: false,
      thStyle: {
        width: '15%',
      },
    },
    {
      key: 'username',
      label: 'Utilizator',
      sortable: false,
      thStyle: {
        width: '15%',
      },
    },
    {
      key: 'userIP',
      label: 'Adresa IP',
      sortable: false,
      thStyle: {
        width: '15%',
      },
    },
    {
      key: 'actionType',
      label: 'Acțiune',
      sortable: false,
      thStyle: {
        width: '15%',
      },
    },
  ];

  async excelExport() {
    window.location.href = `/api/document-log-entry/grn/excel-export/${this.documentId}`;
  }

  formatDate(val, fmt) {
    return format(val, fmt);
  }

  showModal() {
    (<any>(this.$refs.modal)).show();
  }
}

