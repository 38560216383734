








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import Component, { mixins } from 'vue-class-component';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import debounce from 'lodash/debounce';
import Decimal from 'decimal.js';
import camelcaseKeys from 'camelcase-keys';
import printJS from 'print-js';
import { toasts } from '@/utils/toasts';

import { constants } from '@/utils/constants';

import ManageProductCatalogModal from '@/features/catalog-management/product-catalog/ManageProductCatalogModal.vue';
import { formatNumber as fn } from '../../../utils/number-formatting';
import EditGoodsReceivedNoteLineModal from './EditGoodsReceivedNoteLineModal.vue';
import DocumentLogEntriesModal from './DocumentLogEntriesModal.vue';

import {
  lookupSupplier,
  lookupProduct,
  getAvailableInventories,
  getAvailableSeries,
  getAvailableStock,
  saveDraft,
  deleteDraft,
  deleteCanceled,
  restoreDocument,
  cancelDocument,
  compareLatestSeries,
  postDraft,
  getDetails,
  getPdf,
  getDocumentLogs,
} from '../api/goods-received-notes-api';
import GoodsReceivedNoteMixin from './goods-received-note-mixin.vue';

extend('afterOrEqual', (value, afterValue) => parse(value, 'dd / MM / yyyy', new Date()) >= parse(afterValue[0], 'dd / MM / yyyy', new Date()));

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    EditGoodsReceivedNoteLineModal,
    DocumentLogEntriesModal,
    ManageProductCatalogModal,
  },
})
export default class extends mixins(GoodsReceivedNoteMixin) {
  loading = true;

  get Decimal() {
    return Decimal;
  }

  formatNumber(input, dp) {
    return fn(input, dp);
  }

  printNumCopies = 1;
  printPreviewIframe: HTMLIFrameElement|null = null;

  beforeDestroy() {
    // Clean up the print preview IFRAME, we don't need it anymore
    if (this.printPreviewIframe) {
      this.printPreviewIframe.parentElement!.removeChild(this.printPreviewIframe);
    }
  }

  hoveredRowIndex = -1;

  rowHovered(_: unknown, index: number) {
    this.hoveredRowIndex = index;
  }

  rowUnhovered() {
    this.hoveredRowIndex = -1;
  }

  //
  // Preview modal imag source
  //
  previewModalImageShown = false;
  previewModalImageSrc = '/api/grn/jpeg/_placeholder_';

  //
  // Preview modal events
  //
  async onPreviewModalCancel() {
    if (this.$route.name !== 'GoodsReceivedNotes.Details') {
      this.$router.replace({ name: 'GoodsReceivedNotes.Details', params: { id: `${this.goodsReceivedNoteId}` } });
      return;
    }
    await this.initialize();
  }

  async onPreviewModalOk(evt) {
    evt.preventDefault();

    const confirmOutcome = await this.$bvModal.msgBoxConfirm('Salvarea NIR-ului va duce la procesarea acestuia in gestiune. Dacă mai târziu vor fi identificate erori, acestea trebuie corectate printr-o stornare. Dorești să continui?', {
      centered: true,
      okTitle: 'Da',
      cancelTitle: 'Nu',
    });
    if (!confirmOutcome) {
      return;
    }

    try {
      const response = await postDraft(this.goodsReceivedNoteId);
      console.log(response);
    } catch (err) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
      return;
    }

    // We can close the modal now
    this.$bvModal.hide('preview-modal');

    toasts.success('Documentul a fost salvat ca NIR!');
    if (this.$route.name !== 'GoodsReceivedNotes.Details') {
      this.$router.replace({ name: 'GoodsReceivedNotes.Details', params: { id: `${this.goodsReceivedNoteId}` } });
      return;
    }
    await this.initialize();
  }

  documentLogs: Array<any> = [];
  items: Array<any> = [];

  get fields() {
    return [
      {
        key: 'idx',
        label: 'Nr. crt.',
        thClass: 'text-center tbl-col-idx',
        tdClass: 'text-center py-2 tbl-col-idx',
      },
      {
        key: 'inventory',
        label: 'Gestiune',
        thClass: 'tbl-col-inventory',
        tdClass: 'py-2 tbl-col-inventory',
      },
      {
        key: 'product',
        label: 'Produs',
        thClass: 'tbl-col-product',
        tdClass: 'py-2 tbl-col-product',
      },
      {
        key: 'measurementUnit',
        label: 'U.M.',
        thClass: 'text-center tbl-col-mu',
        tdClass: 'py-2 text-center tbl-col-mu',
      },
      {
        key: 'quantity',
        label: 'Cant.',
        thClass: 'text-right tbl-col-qty-value',
        tdClass: 'py-2 text-right tbl-col-qty-value',
      },
      {
        key: 'unitPrice',
        label: 'Preț',
        thClass: 'text-right tbl-col-number-value',
        tdClass: 'py-2 text-right tbl-col-number-value',
      },
      {
        key: 'value',
        label: 'Valoare',
        thClass: 'text-right tbl-col-number-value',
        tdClass: 'py-2 text-right tbl-col-number-value',
      },
      {
        key: 'vatValue',
        label: 'TVA',
        thClass: 'text-right tbl-col-number-value',
        tdClass: 'py-2 text-right tbl-col-number-value',
      },
      {
        key: 'valueWithVat',
        label: 'Val. cu TVA',
        thClass: 'text-right tbl-col-number-value',
        tdClass: 'py-2 text-right tbl-col-number-value',
      },
      {
        key: 'actions',
        label: '',
        thClass: 'text-right tbl-col-actions',
        tdClass: 'text-right tbl-col-actions',
      },
    ];
  }

  //
  // Quanntity/Price/Value event handlers
  //

  onLineValueChanged(value) {
    this.lineValue = value;
    if (!this.sourceDocumentQuantity) {
      this.sourceDocumentQuantity = '1';
      this.receivedQuantity = '1';
    }

    const lineValueDecimal = new Decimal(this.lineValue || '0');
    this.unitPrice = lineValueDecimal.div(this.sourceDocumentQuantity).toFixed(5);
    this.lineVatValue = this.vatPercentage
      ? lineValueDecimal.times(this.vatPercentage.key).toFixed(2)
      : '0';
  }

  onVatPercentageChanged(value) {
    this.vatPercentage = value;
    if (!this.vatPercentage) {
      this.lineVatValue = '0';
    } else {
      this.lineVatValue = new Decimal(this.lineValue || '0').times(value.key).toFixed(2);
    }

    (this.$refs.lineValue as any).focus();
  }

  onUnitPriceChanged(value) {
    this.unitPrice = value;
    if (!this.sourceDocumentQuantity) {
      this.sourceDocumentQuantity = '1';
      this.receivedQuantity = '1';
    }

    const lineValueDecimal = new Decimal(this.unitPrice || '0').times(this.receivedQuantity || '0').toDecimalPlaces(2);
    this.lineValue = lineValueDecimal.toFixed(2);
    this.lineVatValue = this.vatPercentage
      ? lineValueDecimal.times(this.vatPercentage.key).toFixed(2)
      : '0';
  }

  onReceivedQuantityChanged(value) {
    this.receivedQuantity = (!value) ? '0' : value;
    if (!this.sourceDocumentQuantity) {
      this.sourceDocumentQuantity = new Decimal(value).toFixed(3);
    }
    if (!this.unitPrice) {
      this.unitPrice = '0';
    }

    const lineValueDecimal = new Decimal(this.unitPrice || '0').times(this.receivedQuantity || '0').toDecimalPlaces(2);
    this.lineValue = lineValueDecimal.toFixed(2);
    this.lineVatValue = this.vatPercentage
      ? lineValueDecimal.times(this.vatPercentage.key).toFixed(2)
      : '0';
  }

  onSourceDocumentQuantityChanged(value) {
    this.sourceDocumentQuantity = (!value) ? '0' : value;
    this.onReceivedQuantityChanged(value);
  }

  async onProductSelected(value) {
    if (!value) {
      return;
    }

    if (value.isNewProduct) {
      if (await this.$bvModal.msgBoxConfirm('Cod produs inexistent. Doriți generarea lui în Catalog produse?', {
        title: 'Atenție',
        okTitle: 'Da',
        cancelTitle: 'Nu',
        centered: true,
      })) {
        const trimmedCode = this.trimEndingQuestionMarks(value);

        if (trimmedCode !== value.label) {
          if (await this.$bvModal.msgBoxConfirm('Doriți generearea unui cod nou înlocuind "????" cu cifre?', {
            title: 'Atenție',
            okTitle: 'Da',
            cancelTitle: 'Nu',
            centered: true,
          })) {
            this.showAddProductModal(true);
          } else {
            this.showAddProductModal(false);
          }
        } else {
          this.showAddProductModal(false);
        }
      } else {
        this.selectedProduct = null;
      }
    }

    this.productDescription = value.description;
    this.measurementUnit = value.measurementUnit;

    const wantedVatPercentage = this.vatPercentageOptions.find((o) => o.key === value.vatPercentage);
    if (wantedVatPercentage) {
      this.vatPercentage = wantedVatPercentage;
    }

    (this.$refs.productDescriptionInputControl as any).focus();
  }

  trimEndingQuestionMarks(data) {
    return data.label.replace(/\?+$/g, '');
  }

  showAddProductModal(autoGenerateCode) {
    (<any>(this.$refs.addProductModal)).showModal(this.selectedProduct.label, constants.productCatalogModalState.addExternal, autoGenerateCode);
  }

  onClearSupplierClicked() {
    this.supplier = null;
  }

  onSupplierTag() {
    // Stub for when we would want to ADD a supplier right here
  }

  async onMultiSelectSupplierInput(validator, supplier) {
    this.validateWrapper(validator, supplier);

    if (!supplier?.usesVat && supplier) {
      this.vatPercentage = { label: '0 %', key: 0.0 };

      if (this.doesDocumentHaveNonNullVATLines()) {
        this.$bvModal.msgBoxOk('Pozițiile din NIR încărcate sunt cu TVA. Nu puteți schimba furnizorul într-un furnizor neplatitor de TVA');
        this.supplier = supplier ? this.oldVatSupplierValue : null;
      }
    } else {
      if (supplier && this.doesDocumentHaveNullVATLines()) {
        try {
          if (!await this.$bvModal.msgBoxConfirm('Pozițiile din NIR încărcate sunt fară TVA. Schimbați furnizorul din neplatitor de TVA în plătitor de TVA?', {
            title: 'Atenție',
            okTitle: 'Continuă',
            cancelTitle: 'Renunță',
            centered: true,
          })) {
            this.supplier = supplier ? this.oldNullVatSupplierValue : null;
          }
        } catch (err) {
          this.supplier = supplier ? this.oldNullVatSupplierValue : null;
        }
      }
      this.oldVatSupplierValue = supplier;
    }

    if (!supplier?.usesVat) {
      this.oldNullVatSupplierValue = supplier;
    }
  }

  doesDocumentHaveNonNullVATLines() {
    return this.items.filter((item) => parseFloat(item.vatPercentage) !== 0).length > 0;
  }

  doesDocumentHaveNullVATLines() {
    return this.items.filter((item) => parseFloat(item.vatPercentage) === 0).length > 0;
  }

  async onProductAddModalOK(data) {
    this.multiselectProductList = this.multiselectProductList.filter((mpi) => mpi.label !== data.product.productCode);

    const newProduct = {
      description: data.product.name,
      isActive: data.product.isActive,
      label: data.product.productCode,
      measurementUnit: data.product.measurementUnit,
      productId: data.product.productId,
      vatPercentage: data.product.vatPercentage,
    };

    this.productDescription = data.product.name;
    this.measurementUnit = data.product.measurementUnit;

    const wantedVatPercentage = this.vatPercentageOptions.find((o) => o.key === data.product.vatPercentage);
    if (wantedVatPercentage) {
      this.vatPercentage = wantedVatPercentage;
    }

    this.multiselectProductList.push(newProduct);
    this.selectedProduct = newProduct;

    (this.$refs.productDescriptionInputControl as any).focus();
  }

  validateWrapper(validator, value) {
    // Every time we go through this validator, we mark the data as not saved
    if (this.isDraft) {
      this.isSaved = false;
    }

    validator(value);
  }

  dateFormatterDataDocument(value) {
    if (value) {
      this.dataDocumentValueDatepicker = format(parse(value, 'dd / MM / yyyy', new Date()), 'yyyy-MM-dd');
      this.dataScadentaValueDatepickerMin = this.dataDocumentValueDatepicker;
      return format(parse(value, 'dd / MM / yyyy', new Date()), 'dd / MM / yyyy');
    }
    return null;
  }

  dateFormatterDataScadenta(value) {
    if (value) {
      this.dataScadentaValueDatepicker = format(parse(value, 'dd / MM / yyyy', new Date()), 'yyyy-MM-dd');
      return format(parse(value, 'dd / MM / yyyy', new Date()), 'dd / MM / yyyy');
    }
    return null;
  }

  dateFormatterDataIntrare(value) {
    if (value) {
      this.dataIntrareValueDatepicker = format(parse(value, 'dd / MM / yyyy', new Date()), 'yyyy-MM-dd');
      return format(parse(value, 'dd / MM / yyyy', new Date()), 'dd / MM / yyyy');
    }
    return null;
  }

  onContextDataDocument(ctx) {
    this.dataDocumentValue = format(parse(ctx.selectedYMD, 'yyyy-MM-dd', new Date()), 'dd / MM / yyyy');
    this.dataScadentaValueDatepickerMin = ctx.selectedYMD;

    if (this.dataIntrareValueDatepicker < String(this.dataDocumentValueDatepicker)) {
      this.dataIntrareValueDatepicker = String(this.dataDocumentValueDatepicker);
    }
  }

  onContextDataScadenta(ctx) {
    this.dataScadentaValue = format(parse(ctx.selectedYMD, 'yyyy-MM-dd', new Date()), 'dd / MM / yyyy');
  }

  async onContextDataIntrare(ctx) {
    const currentDate = new Date().toISOString().substring(0, 10);

    if (currentDate < ctx.selectedYMD) {
      if (!(await this.$bvModal.msgBoxConfirm('Atenție, data de intrare a documentului este ulterioară datei curente!', {
        okTitle: 'Continuă salvarea',
        cancelTitle: 'Anulează salvarea',
        centered: true,
        hideHeader: true,
        noCloseOnBackdrop: true,
      }))) {
        await this.onContextDataIntrareOk(currentDate);
        return;
      }
      await this.onContextDataIntrareOk(ctx.selectedYMD);
    } else {
      await this.onContextDataIntrareOk(ctx.selectedYMD);
    }
  }

  async onContextDataIntrareOk(value) {
    this.dataIntrareValue = format(parse(value, 'yyyy-MM-dd', new Date()), 'dd / MM / yyyy');
    const ctxDate = parse(value, 'yyyy-MM-dd', new Date());

    if (ctxDate.getFullYear() !== this.lastDataIntrareYear) {
      this.documentSeries = null;
      this.lastDataIntrareYear = ctxDate.getFullYear();
      this.documentSeriesOptions = camelcaseKeys(await getAvailableSeries(this.lastDataIntrareYear));

      if (this.documentSeriesOptions.length === 1) {
        [this.documentSeries] = this.documentSeriesOptions;
      }
    }
  }

  // Diplay fields for the document dates
  dataDocumentValue = '20 . 12 . 2020';

  dataScadentaValue = '20 . 12 . 2020';

  dataIntrareValue = '20 . 12 . 2020';

  lastDataIntrareYear = 0;

  // Min/max values for the date pickers

  dataDocumentValueDatepickerMax = '2099-12-31';

  dataScadentaValueDatepickerMin = '2000-01-01';

  dataIntrareValueDatepickerMax = '2099-12-31';

  //
  // Header-level multiselect fields
  //

  isSupplierOptionsLoading = false;
  supplierOptions = [];

  sourceDocumentTypeOptions = [
    { key: 'FAF', label: 'Factură' },
    { key: 'FAV', label: 'Aviz' },
    { key: 'FSM', label: 'Factură simpificată' },
    { key: 'BON', label: 'Bon fiscal' },
    { key: 'OTH', label: 'Altele' },
  ];

  currencyCodeOptions = [
    { key: 'RON', label: 'RON', description: 'Lei românești' },
    // { key: 'EUR', label: 'EUR', description: 'Euro' },
  ];

  documentSeriesOptions: Array<any> = [];
  isDocumentSeriesOptionsLoading = false;

  // Computed current user name getter
  get currentUserFullName() {
    return this.$store.state.fullName;
  }

  // Satus
  isSaved = false;

  //
  // Header fields
  //

  goodsReceivedNoteId: number|null = null;
  get isDraft(): Boolean {
    return this.statusCode === 0;
  }
  get isCanceled(): Boolean {
    return this.statusCode === 2;
  }
  get isDocumentCreated() : Boolean {
    return this.goodsReceivedNoteId !== null;
  }
  statusCode: number = 0;

  // The entire partner lookup object
  supplier: any|null = null;
  oldVatSupplierValue: any|null = null;
  oldNullVatSupplierValue: any|null = null;
  sourceDocumentType = { key: 'FAF', label: 'Factură' };
  sourceDocumentNumber = '';
  dataDocumentValueDatepicker: Date|string = '2020-12-20';
  dataScadentaValueDatepicker: Date|string = '2020-12-20';
  currencyCode = { key: 'RON', label: 'RON', description: 'lei românești' };
  currencyExchangeRate = 1;

  // The entire series object
  documentSeries: any|null = null;
  documentNumber = '';
  dataIntrareValueDatepicker = '2020-12-20';

  //
  // Footer fields
  //
  deliveryVehicleIdentifier: string|null = null;
  deliveryDelegate: string|null = null;
  documentNotes: string|null = null;
  privateNotes: string|null = null;
  receivingCommitteeMembers: string|null = null;
  receivedBy: string|null = null;

  isLastDocument: boolean = false;

  // Row-level multiselect fields

  allInventoryOptions: Array<any> = [];
  inventoryOptions: Array<any> = [];
  isInventoryOptionsLoading = false;

  multiselectProductList: Array<any> = [];
  multiselectProductLoading = false;

  get vatPercentageOptions() {
    return (!this.supplier || this.supplier.usesVat) ? [
      { label: '19 %', key: 0.19 },
      { label: '9 %', key: 0.09 },
      { label: '5 %', key: 0.05 },
      { label: '0 %', key: 0.0 },
    ] : [
      { label: '0 %', key: 0.0 },
    ];
  }

  // Row fields

  // The entire inventory details object
  inventory: any|null = null;

  // The entire product details object (productid, label, description, measurement unit, whether it is active)
  selectedProduct: any|null = null;

  // Copied from the product on selection
  productDescription: string|null = null;
  lineNotes: string|null = null;
  // Copied from the product on selection
  measurementUnit: string|null = null;

  // These affect each other
  sourceDocumentQuantity: string | null = null;
  receivedQuantity: string | null = null;
  unitPrice: string | null = null;
  // This is actually an object, the key is the VAT percentage
  vatPercentage? = { label: '19 %', key: 0.19 };
  lineValue: string | null = null;
  lineVatValue: string | null = null;

  //
  // Search functions
  //

  async asyncSearchProducts(query: string) {
    if (!query || query.length < 1) {
      return Promise.resolve();
    }

    this.multiselectProductLoading = true;

    this.multiselectProductList = camelcaseKeys(await lookupProduct(query));

    if (!this.doesProductListContainProductCode(query)) {
      this.multiselectProductList.unshift({
        label: query,
        isNewProduct: true,
      });
    }

    this.multiselectProductLoading = false;
    return Promise.resolve();
  }

  doesProductListContainProductCode(productCode) {
    return !!this.multiselectProductList.find((mpu) => mpu.label === productCode);
  }

  async asyncSearchInventory() {
    if (this.allInventoryOptions.length) {
      this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);
      return Promise.resolve();
    }

    this.isInventoryOptionsLoading = true;

    this.allInventoryOptions = camelcaseKeys(await getAvailableInventories());
    this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);

    this.isInventoryOptionsLoading = false;
    return Promise.resolve();
  }

  async asyncSearchSupplier(query) {
    if (!query || query.length < 2) {
      return Promise.resolve();
    }

    this.isSupplierOptionsLoading = true;

    this.supplierOptions = camelcaseKeys(await lookupSupplier(query));

    this.isSupplierOptionsLoading = false;
    return Promise.resolve();
  }

  //
  // Search functions (debounced)
  //

  debouncedSearchProducts = debounce(this.asyncSearchProducts, 75, { maxWait: 150 });
  debouncedSearchInventory = debounce(this.asyncSearchInventory, 75, { maxWait: 150 });
  debouncedSearchSupplier = debounce(this.asyncSearchSupplier, 250, { maxWait: 750 });

  //
  // Event handlers
  //

  onInventorySelected(option) {
    if (!option) {
      return;
    }

    if (option.receivingCommitteeMembers && !this.receivingCommitteeMembers) {
      this.receivingCommitteeMembers = option.receivingCommitteeMembers;
    }

    if (option.storekeeper && !this.receivedBy) {
      this.receivedBy = option.storekeeper;
    }
  }

  onEditLine(line) {
    (<any>(this.$refs.editGoodsReceivedNoteLineModal))
      .showModal(line, this.receivedBy, this.receivingCommitteeMembers);
  }

  onEditLineEvent({ itemLine, receivedBy, receivingCommitteeMembers }) {
    this.receivedBy = receivedBy;
    this.receivingCommitteeMembers = receivingCommitteeMembers;

    const item = this.items.find((e) => e.lineNumber === itemLine.lineNumber);
    if (!item) {
      this.showErrorsToast('Linia nu a putut fi actualizată', 'mrnDraftLineAddError');
      return;
    }

    item.inventoryCode = itemLine.inventoryCode;
    item.inventoryId = itemLine.inventoryId;
    item.inventoryName = itemLine.inventoryName;
    item.lineNotes = itemLine.lineNotes;
    item.measurementUnit = itemLine.measurementUnit;
    item.lineValue = itemLine.lineValue;
    item.lineVatValue = itemLine.lineVatValue;
    item.productCode = itemLine.productCode;
    item.productDescription = itemLine.productDescription;
    item.productId = itemLine.productId;
    item.receivedQuantity = itemLine.receivedQuantity;
    item.sourceDocumentQuantity = itemLine.sourceDocumentQuantity;
    item.unitPrice = itemLine.unitPrice;
    item.vatPercentage = itemLine.vatPercentage;
  }

  onRemoveLine(line) {
    this.items = this.items.filter((i) => i !== line);
    this.items.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.lineNumber = index + 1;
    });

    this.isSaved = false;
  }

  validateItemLine(itemLine) {
    const errors: Array<string> = [];

    if (!itemLine.inventoryId) {
      errors.push('Gestiune neselectată');
    }

    // For now only allow inventoried entries
    if (!itemLine.productId) {
      errors.push('Produs neselectat');
    }
    if (!itemLine.measurementUnit) {
      errors.push('U.M. necompletată');
    }
    if (!itemLine.sourceDocumentQuantity) {
      errors.push('Cantitate furnizor necompletată');
    }
    if (!itemLine.receivedQuantity) {
      errors.push('Cantitate primită necompletată');
    }
    if (!itemLine.unitPrice) {
      errors.push('Preț necompletat');
    }
    if (!this.vatPercentage) {
      errors.push('Procent TVA necompletat');
    }
    if (itemLine.unitPrice && new Decimal(itemLine.unitPrice).lt(0) && !!itemLine.inventoryId) {
      errors.push('Prețul de intrare în gestiune nu poate fi negativ');
    }
    if (itemLine.unitPrice && new Decimal(itemLine.unitPrice).eq(0) && !!itemLine.inventoryId) {
      errors.push('Prețul de intrare în gestiune nu poate fi zero; mostrele trebuie introduse în gestiune la prețul lor just.');
    }

    return errors;
  }

  async onAddLine() {
    if (!this.isDraft) {
      return;
    }

    const itemLine = {
      lineNumber: this.items.length + 1,
      inventoryName: this.inventory?.description,
      inventoryCode: this.inventory?.label,
      inventoryId: this.inventory?.inventoryId,
      productDescription: this.productDescription,
      productCode: this.selectedProduct?.label,
      productId: this.selectedProduct?.productId,
      lineNotes: this.lineNotes,
      measurementUnit: this.measurementUnit,
      sourceDocumentQuantity: this.sourceDocumentQuantity,
      receivedQuantity: this.receivedQuantity!,
      unitPrice: this.unitPrice,
      lineValue: this.lineValue,
      lineVatValue: this.lineVatValue,
      vatPercentage: this.vatPercentage?.key,
    };

    const errors = this.validateItemLine(itemLine);
    if (errors.length) {
      this.showErrorsToast(errors, 'grnDraftLineAddError');
      return;
    }

    // Check inventory for the current product code, looking at all lines in the document
    let totalReceived = new Decimal(itemLine.receivedQuantity);
    this.items.filter((e) => e.productId === itemLine.productId && e.inventoryId === itemLine.inventoryId).forEach((item) => {
      totalReceived = totalReceived.plus(new Decimal(item.receivedQuantity));
    });

    // Pe server intai vom opera intrarile si apoi iesirile, deci trebuie doar ca suma cantitatilor sa fie mai mica decat zero
    // ca sa fie necesar sa verificam daca e stoc suficient
    if (new Decimal(totalReceived).lt(0)) {
      const availableStock = await getAvailableStock({
        lookupDate: format(parse(this.dataIntrareValue, 'dd / MM / yyyy', new Date()), 'yyyy-MM-dd'),
        productId: itemLine.productId,
        inventoryId: itemLine.inventoryId,
        unitPrice: new Decimal(itemLine.unitPrice || '0').toNumber(),
      });

      if (new Decimal(totalReceived).times(-1).gt(availableStock.totalQuantity)) {
        this.$bvModal.msgBoxOk(`Stoc insuficient în gestiune (${this.formatNumber(availableStock.totalQuantity, 3)} unități) pentru produsul selectat!`);
        return;
      }
    }

    this.items.push(itemLine);
    this.isSaved = false;

    this.cleanLineInput();
  }

  getLatestUsedDocumentSeriesIndex(option) {
    return option.startIndex !== option.nextIndex
      ? option.nextIndex - 1
      : option.startIndex;
  }

  cleanLineInput() {
    this.selectedProduct = null;
    this.productDescription = null;
    this.lineNotes = null;
    this.measurementUnit = 'BUC';
    this.sourceDocumentQuantity = null;
    this.receivedQuantity = null;
    this.unitPrice = null;
    this.vatPercentage = (this.supplier && !this.supplier.usesVat) ? { label: '0 %', key: 0.00 } : { label: '19 %', key: 0.19 };
    this.lineValue = null;
    this.lineVatValue = null;
  }

  async initialize() {
    this.items = [];

    this.allInventoryOptions = camelcaseKeys(await getAvailableInventories());
    this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);

    this.documentSeriesOptions = camelcaseKeys(await getAvailableSeries(new Date().getFullYear()));

    let existingData: any|null = null;
    let copyData: any|null = null;
    let returnNoteData: any|null = null;

    try {
      if (this.$route.params.id) {
        existingData = camelcaseKeys(await getDetails(this.$route.params.id));
      } else if (this.$route.query.copyFromId) {
        copyData = camelcaseKeys(await getDetails(this.$route.query.copyFromId));
      } else if (this.$route.query.returnFromId) {
        returnNoteData = camelcaseKeys(await getDetails(this.$route.query.returnFromId));
      }
    } catch (err: any) {
      toasts.error(err.response?.data?.errorMessage);
      return;
    }

    // These maximums never should change
    this.dataDocumentValueDatepickerMax = format(new Date(), 'yyyy-MM-dd');

    if (!existingData) {
      //
      // Fresh object!
      //
      this.dataDocumentValueDatepicker = format(new Date(), 'yyyy-MM-dd');
      this.dataScadentaValueDatepicker = format(new Date(), 'yyyy-MM-dd');
      this.dataIntrareValueDatepicker = format(new Date(), 'yyyy-MM-dd');

      this.dataScadentaValueDatepickerMin = format(new Date(), 'yyyy-MM-dd');

      this.lastDataIntrareYear = (new Date()).getFullYear();

      if (this.documentSeriesOptions.length === 1) {
        [this.documentSeries] = this.documentSeriesOptions;
      }

      if (copyData) {
        this.supplier = copyData.supplier;
        this.receivedBy = copyData.receivedBy;
        this.receivingCommitteeMembers = copyData.receivingCommitteeMembers;

        if (!this.supplier?.usesVat) {
          this.oldNullVatSupplierValue = this.supplier;
        } else {
          this.oldVatSupplierValue = this.supplier;
        }

        copyData.lines.forEach((line) => {
          const itemLine = {
            lineNumber: line.lineNumber,
            inventoryName: line.inventoryName,
            inventoryCode: line.inventoryCode,
            inventoryId: line.inventoryId,
            productDescription: line.productDescription,
            productCode: line.productCode,
            productId: line.productId,
            lineNotes: line.lineNotes,
            measurementUnit: line.measurementUnit,
            sourceDocumentQuantity: line.sourceDocumentQuantity,
            receivedQuantity: line.receivedQuantity,
            unitPrice: line.unitPrice,
            lineValue: line.lineValue,
            lineVatValue: line.lineVatValue,
            vatPercentage: line.vatPercentage,
          };

          this.items.push(itemLine);
        });
      }

      if (returnNoteData) {
        this.supplier = returnNoteData.supplier;
        this.receivedBy = returnNoteData.receivedBy;
        this.receivingCommitteeMembers = returnNoteData.receivingCommitteeMembers;

        if (!this.supplier?.usesVat) {
          this.oldNullVatSupplierValue = this.supplier;
        } else {
          this.oldVatSupplierValue = this.supplier;
        }

        returnNoteData.lines.forEach((line) => {
          const itemLine = {
            lineNumber: line.lineNumber,
            inventoryName: line.inventoryName,
            inventoryCode: line.inventoryCode,
            inventoryId: line.inventoryId,
            productDescription: line.productDescription,
            productCode: line.productCode,
            productId: line.productId,
            lineNotes: `Retur de pe ${returnNoteData.sourceDocumentType} ${returnNoteData.sourceDocumentNumber} din ${format(parseISO(returnNoteData.sourceDocumentDate), 'dd /MM / yyyy')}`,
            measurementUnit: line.measurementUnit,
            // Received qty is reversed
            sourceDocumentQuantity: new Decimal(line.sourceDocumentQuantity).mul(-1).toNumber(),
            receivedQuantity: new Decimal(line.receivedQuantity).mul(-1).toNumber(),
            unitPrice: line.unitPrice,
            lineValue: new Decimal(line.lineValue).mul(-1).toNumber(),
            lineVatValue: new Decimal(line.lineVatValue).mul(-1).toNumber(),
            vatPercentage: line.vatPercentage,
          };

          this.items.push(itemLine);
        });
      }
    } else {
      //
      // We have a model ID!
      //

      this.statusCode = existingData.statusCode;
      this.goodsReceivedNoteId = existingData.goodsReceivedNoteId;
      this.documentNumber = existingData.documentNumber;
      this.documentSeries = existingData.documentSeries;
      this.supplier = existingData.supplier;
      this.sourceDocumentNumber = existingData.sourceDocumentNumber;
      this.sourceDocumentType = {
        key: existingData.sourceDocumentTypeCode,
        label: existingData.sourceDocumentType,
      };

      if (!this.supplier?.usesVat) {
        this.oldNullVatSupplierValue = this.supplier;
      } else {
        this.oldVatSupplierValue = this.supplier;
      }

      this.vatPercentage = (this.supplier && !this.supplier.usesVat) ? { label: '0 %', key: 0.00 } : { label: '19 %', key: 0.19 };

      this.dataDocumentValueDatepicker = format(parseISO(existingData.sourceDocumentDate), 'yyyy-MM-dd');
      this.dataScadentaValueDatepicker = format(parseISO(existingData.sourceDocumentDueDate), 'yyyy-MM-dd');
      this.dataIntrareValueDatepicker = format(parseISO(existingData.documentDate), 'yyyy-MM-dd');

      this.dataDocumentValue = format(parseISO(existingData.sourceDocumentDate), 'dd /MM / yyyy');
      this.dataScadentaValue = format(parseISO(existingData.sourceDocumentDueDate), 'dd /MM / yyyy');
      this.dataIntrareValue = format(parseISO(existingData.documentDate), 'dd /MM / yyyy');

      // Footer
      this.deliveryVehicleIdentifier = existingData.deliveryVehicleIdentifier;
      this.deliveryDelegate = existingData.deliveryDelegate;
      this.documentNotes = existingData.documentNotes;
      this.privateNotes = existingData.privateNotes;
      this.receivingCommitteeMembers = existingData.receivingCommitteeMembers;
      this.receivedBy = existingData.receivedBy;
      this.isLastDocument = existingData.isLastDocument;

      this.lastDataIntrareYear = (new Date()).getFullYear();

      existingData.lines.forEach((line) => {
        const itemLine = {
          goodsReceivedNoteLineId: line.goodsReceivedNoteLineId,
          lineNumber: line.lineNumber,
          inventoryName: line.inventoryName,
          inventoryCode: line.inventoryCode,
          inventoryId: line.inventoryId,
          productDescription: line.productDescription,
          productCode: line.productCode,
          productId: line.productId,
          lineNotes: line.lineNotes,
          measurementUnit: line.measurementUnit,
          sourceDocumentQuantity: line.sourceDocumentQuantity,
          receivedQuantity: line.receivedQuantity,
          unitPrice: line.unitPrice,
          lineValue: line.lineValue,
          lineVatValue: line.lineVatValue,
          vatPercentage: line.vatPercentage,
        };

        this.items.push(itemLine);
      });

      // We're clean
      this.isSaved = true;
    }

    this.loading = false;
  }

  async onReversalModalOk() {
    this.$router.push({ name: 'GoodsReceivedNotes.Create', query: { returnFromId: `${this.goodsReceivedNoteId}` } });
  }

  async onReverseNote() {
    this.$bvModal.show('reversal-modal');
  }

  async printDocument(numCopies = 1) {
    let data: any;

    try {
      data = await getPdf(this.goodsReceivedNoteId, numCopies);
    } catch (err) {
      this.$bvModal.msgBoxOk('A intervenit o eroare la pregătirea documentului pentru imprimare! Încearcă din nou în câteva momente.');
      return;
    }

    if (!(data instanceof ArrayBuffer)) {
      this.$bvModal.msgBoxOk('A intervenit o eroare la pregătirea documentului pentru imprimare! Încearcă din nou în câteva momente.');
      return;
    }

    const base64Text = btoa(new Uint8Array(<any>data).reduce((d, byte) => d + String.fromCharCode(byte), ''));
    printJS({
      printable: base64Text,
      base64: true,
    });

    // Use printjs instead
    /*
    const blob = new Blob([data], { type: 'application/pdf' });

    if (this.printPreviewIframe) {
      this.printPreviewIframe.parentElement!.removeChild(this.printPreviewIframe);
    }

    this.printPreviewIframe = document.createElement('iframe'); // Create an IFrame.
    this.printPreviewIframe.style.visibility = 'hidden'; // Hide the frame.
    this.printPreviewIframe.src = URL.createObjectURL(blob);// Set source.
    (this.$refs.componentRoot as Element).appendChild(this.printPreviewIframe); // Add the frame to the component - this way it gets cleaned up when we leave

    this.printPreviewIframe.contentWindow!.focus(); // Set focus.
    this.printPreviewIframe.contentWindow!.print(); // Print it.
    */
  }

  async onPrintModal() {
    const numCopiesDecimal = new Decimal(this.printNumCopies);
    if (!numCopiesDecimal.isInteger()
    || numCopiesDecimal.lte(0)
    || numCopiesDecimal.gte(9)) {
      this.$bvModal.msgBoxOk('Numărul de copii care vor fi imprimate trebuie să fie între 1 și 9');
      return;
    }
    await this.printDocument(numCopiesDecimal.toNumber());
  }

  onPrint() {
    this.$bvModal.show('print-modal');
  }

  async onDeleteDraft() {
    if (!(await this.$bvModal.msgBoxConfirm('Dorești să ștergi acest document?', {
      title: 'Atenție',
      okTitle: 'Da',
      cancelTitle: 'Nu',
      centered: true,
    }))) {
      return;
    }

    try {
      if (this.goodsReceivedNoteId) {
        await deleteDraft(this.goodsReceivedNoteId);
      }

      toasts.success('Documentul a fost eliminat!');
      this.$router.replace({ name: 'GoodsReceivedNotes.Index' });
    } catch (err: any) {
      console.log(err.response);
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  async onDeleteCanceled() {
    if (!(await this.$bvModal.msgBoxConfirm('Dorești să ștergi acest document?', {
      title: 'Atenție',
      okTitle: 'Da',
      cancelTitle: 'Nu',
      centered: true,
    }))) {
      return;
    }

    try {
      if (this.goodsReceivedNoteId) {
        await deleteCanceled(this.goodsReceivedNoteId);
      }

      toasts.success('Documentul a fost eliminat!');
      this.$router.replace({ name: 'GoodsReceivedNotes.Index' });
    } catch (err: any) {
      console.log(err.response);
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  async onCancelDocument() {
    if (!(await this.$bvModal.msgBoxConfirm('Dorești să anulezi acest document? Produsele intrate în gestiune vor fi eliminate.', {
      title: 'Atenție',
      okTitle: 'Da',
      cancelTitle: 'Nu',
      centered: true,
    }))) {
      return;
    }

    if (this.goodsReceivedNoteId) {
      try {
        await cancelDocument(this.goodsReceivedNoteId);

        toasts.success('Documentul a fost anulat!');
        this.$router.replace({ name: 'GoodsReceivedNotes.Index' });
      } catch (err: any) {
        toasts.error(err.response?.data?.errorMessage);
      }
    }
  }

  async onRestoreDocument() {
    if (!(await this.$bvModal.msgBoxConfirm('Dorești să restaurezi acest document?', {
      title: 'Atenție',
      okTitle: 'Da',
      cancelTitle: 'Nu',
      centered: true,
    }))) {
      return;
    }

    if (this.goodsReceivedNoteId) {
      try {
        await restoreDocument(this.goodsReceivedNoteId);

        toasts.success('Documentul a fost restaurat!');
        this.$router.replace({ name: 'GoodsReceivedNotes.Index' });
      } catch (err: any) {
        toasts.error(err.response.data.errorMessage);
      }
    }
  }

  async onShowDocumentLogs() {
    const logs = await getDocumentLogs(this.goodsReceivedNoteId);
    this.documentLogs = logs.items?.reverse()?.map((e, index) => ({ ...e, index: index + 1 }));
    this.documentLogs = this.documentLogs.reverse();
    (<any>(this.$refs.documentLogEntriesModal)).showModal();
  }

  async onSaveDocument() {
    const errors: Array<string> = [];

    if (!this.supplier) {
      errors.push('Furnizor neselectat');
    }

    if (!this.sourceDocumentType) {
      errors.push('Tip document neselectat');
    }

    if (!this.sourceDocumentNumber) {
      errors.push('Număr document neselectat');
    }

    if (!this.currencyCode) {
      errors.push('Valuta neselectată');
    }

    if (!this.items.length) {
      errors.push('Trebuie să adaugi măcar o linie');
    }

    if (!this.receivingCommitteeMembers) {
      errors.push('Comisie de recepție necompletată');
    }

    if (!this.receivedBy) {
      errors.push('Nume gestionar necompletat');
    }

    if (!this.documentSeries) {
      errors.push('Serie NIR neselectată');
    }

    if (!this.items || !this.items.length) {
      errors.push('Documentul nu are linii');
    }

    if (this.dataScadentaValueDatepicker < this.dataDocumentValueDatepicker) {
      errors.push('Data scadență trebuie sa fie mai mare sau egala cu data documentului');
    }

    if (this.dataIntrareValueDatepicker < this.dataDocumentValueDatepicker) {
      errors.push('Data de intrare trebuie sa fie mai mare sau egala cu data documentului');
    }

    if (errors.length) {
      this.showErrorsToast(errors, 'grnDraftSaveError');
      return;
    }

    const draft = {
      goodsReceivedNoteId: this.goodsReceivedNoteId,

      sourceDocumentSupplierId: this.supplier!.partnerId,
      sourceDocumentTypeCode: this.sourceDocumentType.key,
      sourceDocumentNumber: this.sourceDocumentNumber,
      sourceDocumentDate: this.dataDocumentValueDatepicker,
      sourceDocumentDueDate: this.dataScadentaValueDatepicker,

      documentDate: this.dataIntrareValueDatepicker,
      documentSeriesId: this.documentSeries.documentSeriesId,

      // TODO: de-hardcode this
      currencyCode: 'RON',
      currencyExchangeRage: 1,

      deliveryVehicleIdentifier: this.deliveryVehicleIdentifier,
      deliveryDelegate: this.deliveryDelegate,

      privateNotes: this.privateNotes,
      documentNotes: this.documentNotes,
      receivingCommitteeMembers: this.receivingCommitteeMembers,
      receivedBy: this.receivedBy,

      // TODO: order by line number, perhaps? Just in case they're not in order
      lines: this.items.map((item) => ({
        goodsReceivedNoteLineId: item.doodsReceivedNoteLineId,
        lineTypeCode: 'GDS',
        inventoryId: item.inventoryId,
        productId: item.productId,
        productDescription: item.productDescription,
        measurementUnit: item.measurementUnit,
        sourceDocumentQuantity: item.sourceDocumentQuantity,
        receivedQuantity: item.receivedQuantity,
        unitPrice: item.unitPrice,
        vatPercentage: item.vatPercentage,
        // vatTypeCode
        unitPriceIncludingVat: new Decimal(new Decimal(item.unitPrice).times(item.vatPercentage).toFixed(5)).plus(item.unitPrice).toFixed(2),
        lineValue: item.lineValue,
        lineVatValue: item.lineVatValue,
        lineValueIncludingVat: new Decimal(item.lineValue).plus(item.lineVatValue).toFixed(2),
        lineNotes: item.lineNotes,
      })),
    };

    try {
      const saveResponse = camelcaseKeys(await saveDraft(draft));
      toasts.success('Documentul a fost salvat ca ciornă!');
      this.isSaved = true;
      this.goodsReceivedNoteId = saveResponse.item1.goodsReceivedNoteId;

      const compareLatestReponse = await compareLatestSeries(this.goodsReceivedNoteId);
      if (compareLatestReponse.isCurrentDocumentDateEarlierThanLatest) {
        this.$bvModal.show('previous-series-warning-modal');
      } else {
        this.previewModalImageSrc = `/api/grn/jpeg/${this.goodsReceivedNoteId}?cb=${+new Date()}`;
        this.$bvModal.show('preview-modal');
      }
    } catch (err: any) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  async onPreviousSeriesWarningModalOk(evt) {
    evt.preventDefault();

    this.previewModalImageSrc = `/api/grn/jpeg/${this.goodsReceivedNoteId}?cb=${+new Date()}`;
    this.$bvModal.show('preview-modal');
  }

  async beforeRouteLeave(to, from, next) {
    if (this.isSaved) {
      next();
      return;
    }

    try {
      if (await this.$bvModal.msgBoxConfirm('Informațiile nesalvate vor fi pierdute! Dorești să părăsești pagina curentă?', {
        title: 'Atenție',
        okTitle: 'Da',
        cancelTitle: 'Nu',
        centered: true,
      })) {
        next();
      }
      next(false);
    } catch (err) {
      next(false);
    }
  }

  async created() {
    await this.initialize();
  }
}
